import { useState, useEffect, FC } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

import logo from '../assets/img/logo.png';
import navIcon1 from '../assets/icon/nav-icon1.svg';
import navIcon2 from '../assets/icon/nav-icon2.svg';
import navIcon3 from '../assets/icon/nav-icon3.svg';
import cvPdf from '../assets/doc/CV_Base.pdf';
import '../style/components/NavBar.css';

const NavBar: FC = () => {
  const [activeLink, setActiveLink] = useState<string>('home');
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value: string) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="md" className={scrolled ? 'scrolled' : ''}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" className="logo" />
          {/* <a href="https://www.flaticon.com/free-icons/world-pride-day" title="world pride day icons">World pride day icons created by Freepik - Flaticon</a> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href="#home"
              className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('skills')}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('projects')}
            >
              Projects
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/zhenyuan-li/" target="_blank">
                <img src={navIcon1} alt="Linkedln" />
              </a>
              <a href="https://twitter.com/Zhenyuan0Li" target="_blank">
                <img src={navIcon2} alt="twitter" />
              </a>
              <a href="https://github.com/Zhenyuan-Li" target="_blank">
                <img src={navIcon3} alt="github" />
              </a>
            </div>

            <button className="vvd" onClick={() => window.open(cvPdf)}>
              <span>View CV</span>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
