import { FC } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import meter1 from '../assets/icon/meter1.svg';
import meter2 from '../assets/icon/meter2.svg';
import meter3 from '../assets/icon/meter3.svg';
import awsPdf from '../assets/doc/aws-saa.pdf';

import '../style/components/Skills.css';
import colorSharp from '../assets/img/color-sharp.png';

const Skills: FC = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                I specialize in front-end development using <strong>React</strong> and back-end
                development in <strong>Node</strong>. I have also been working with some
                production-specific frameworks like <strong>Next, Express, and Nest...</strong> and
                deploying them using suitable cloud services (
                <a href={awsPdf} target="_blank">
                  AWS Certified
                </a>
                ).
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={meter1} alt="Image" />
                  <h5>JavaScript / TypeScript</h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="Image" />
                  <h5>React & Redux</h5>
                  <h6>Next, Styled Components, Material UI...</h6>
                </div>
                <div className="item">
                  <img src={meter3} alt="Image" />
                  <h5>Node</h5>
                  <h6>Express, Nest, GraphQL...</h6>
                </div>
                <div className="item">
                  <img src={meter2} alt="Image" />
                  <h5>Database</h5>
                  <h6>Postgres, MongoDB, Redis...</h6>
                </div>
                <div className="item">
                  <img src={meter2} alt="Image" />
                  <h5>DevOps</h5>
                  <h6>CI/CD, Docker, AWS Service...</h6>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};

export default Skills;
