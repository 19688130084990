import projImg1 from '../../assets/img/projects/zl-jspg.png';
import projImg2 from '../../assets/img/projects/health-hero.png';
import projImg3 from '../../assets/img/projects/vincent-zone.png';
import projImg4 from '../../assets/img/projects/broomer.png';
import projImg5 from '../../assets/img/projects/my-burger.png';
import projImg6 from '../../assets/img/projects/weather-app.png';
import projImg7 from '../../assets/img/projects/car-value-estimate.png';
import projImg8 from '../../assets/img/projects/vincent-portfolio.png';

export interface ProjectCardDetail {
  title: string;
  description: string;
  techStack: string;
  url: string;
  role: string;
  imgUrl: string;
}

export const projectsA: ProjectCardDetail[] = [
  {
    title: 'zl-jspg',
    description: 'A lightweight & easy-to-use code-editor for Markdown & JavaScript.',
    techStack: 'TypeScript, React&Redux, ESbuild, Express...',
    url: 'https://dolphin-app-kpjiv.ondigitalocean.app/',
    role: 'Full Stack Developer',
    imgUrl: projImg1
  },
  {
    title: 'Health Hero',
    description:
      'A cross-platform mobile application that generates weekly meal plans, analysis users’ eating habit and summarise nutritional report.',
    techStack: 'Flutter(Dart), Express, Tensorflow Lite... ',
    url: 'https://drive.google.com/file/d/1zWZx4138pivJOT8eeYN4DF7UFhWMKIom/view',
    role: 'Team Leader & Full Stack Developer',
    imgUrl: projImg2
  },
  {
    title: 'Vincent Zone',
    description:
      'Zhenyuan Li’s technique blog website to record notes along the learning process. Also, keep track of events held by him and his friends.',
    techStack: 'Next, React.js, Styled-Component, MongoDB...',
    url: 'https://vincent-zone.vercel.app/',
    role: 'Front-end Developer',
    imgUrl: projImg3
  },
  {
    title: 'Broomer',
    description:
      'A single-page web application which helps clients book the nearest and most convenient house cleaning services. Also, provide cleaners with a platform to accept order and earn money.		',
    techStack: 'React.js, Material UI, Sass...',
    url: 'https://broomer-ec9zy.ondigitalocean.app/',
    role: 'Front-end Developer',
    imgUrl: projImg4
  }
];

export const projectsB: ProjectCardDetail[] = [
  {
    title: 'My Burger',
    description: 'An online burger ordering and delivering web application.',
    techStack: 'React & Redux, GraphQL, Apollo...',
    url: 'https://react-burger-94d69.firebaseapp.com/',
    role: 'Full Stack Developer',
    imgUrl: projImg5
  },
  {
    title: 'VWeather',
    description:
      'A location-based weather forecast web application using data retrieved from OpenWeatherMap API.',
    techStack: 'TypeScript, React&Redux, Express, Docker...',
    url: 'https://zhenyuan-li.github.io/weather-app-frontend/',
    role: 'Full Stack Developer',
    imgUrl: projImg6
  },
  {
    title: 'Car Value Estimate API',
    description: 'A pre-owned vehicle value estimated API using a dummy approach.',
    techStack: 'TypeScript, Nest, Postgres, Swagger Doc...',
    url: 'https://vincent-car-value.herokuapp.com/api/#/',
    role: 'Back-end Developer',
    imgUrl: projImg7
  },
  {
    title: 'Vincent Portfolio',
    description:
      'Zhenyuan Li’s personal website contains his basic information, skills, projects & contact details.',
    techStack: 'TypeScript, React',
    url: 'https://vincentzli.com/',
    role: 'Front-end Developer',
    imgUrl: projImg8
  }
];
