import { FC } from 'react';
import { Col } from 'react-bootstrap';

import { ProjectCardDetail } from './ProjectDetails';
import '../../style/components/Projects.css';

const ProjectCard: FC<ProjectCardDetail> = ({
  title,
  description,
  techStack,
  url,
  role,
  imgUrl
}) => {
  return (
    <Col size={12} sm={6} md={6} lg={6}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>
            <a href={url} target="_blank">
              {title}
            </a>
          </h4>
          <h5>Tech Stack: {techStack}</h5>
          <h6>Role: {role}</h6>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  );
};

export default ProjectCard;
