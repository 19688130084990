import { SyntheticEvent, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import contactImg from '../assets/icon/contact-img.svg';
import '../style/components/Contact.css';

enum FormStatus {
  success = 'success',
  error = 'danger',
  loading = 'loading'
}

interface StatusData {
  resStatus: FormStatus;
  message: string;
}

const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState<StatusData | null>();

  const onFormUpdate = (category: string, value: string) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
  };

  const validateEmail = (email: string) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const formValidation = (): boolean => {
    // 1 empty field validation;
    // eslint-disable-next-line prefer-const
    let emptyField: string[] = [];
    Object.entries(formDetails).forEach(([key, value]) => {
      if (value === '') {
        emptyField.push(key);
      }
    });
    if (emptyField.length !== 0) {
      setStatus({
        resStatus: FormStatus.error,
        message: `${emptyField.join(', ')} ${
          emptyField.length === 1 ? 'is' : 'are'
        } empty, please fill ${emptyField.length === 1 ? 'it' : 'them'} in`
      });
      return false;
    }

    // email
    if (!validateEmail) {
      setStatus({
        resStatus: FormStatus.error,
        message: `email is invalid`
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setStatus({ resStatus: FormStatus.loading, message: 'Sending the message... ...' });
    if (!formValidation()) return;

    setButtonText('Sending...');

    const response = await fetch(`${process.env.REACT_APP_MAIL_SERVER}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(formDetails)
    });

    setButtonText('Send');
    let result: any;
    try {
      result = await response.json();
    } catch (_) {
      setStatus({
        resStatus: FormStatus.error,
        message: 'Something went wrong, please try again later.'
      });
    }

    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ resStatus: FormStatus.success, message: 'Message sent successfully' });
    } else {
      setStatus({
        resStatus: FormStatus.error,
        message: 'Something went wrong, please try again later.'
      });
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <img className="animate__animated animate__zoomIn" src={contactImg} alt="Contact Us" />
          </Col>
          <Col size={12} md={6}>
            <div className="animate__animated animate__fadeIn">
              <h2>Get In Touch</h2>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="text"
                      value={formDetails.firstName}
                      placeholder="First Name"
                      onChange={(e) => onFormUpdate('firstName', e.target.value)}
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="text"
                      value={formDetails.lastName}
                      placeholder="Last Name"
                      onChange={(e) => onFormUpdate('lastName', e.target.value)}
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="email"
                      value={formDetails.email}
                      placeholder="Email Address"
                      onChange={(e) => onFormUpdate('email', e.target.value)}
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="tel"
                      value={formDetails.phone}
                      placeholder="Phone No."
                      onChange={(e) => onFormUpdate('phone', e.target.value)}
                    />
                  </Col>
                  <Col size={12} className="px-1">
                    <textarea
                      rows={6}
                      value={formDetails.message}
                      placeholder="Message"
                      onChange={(e) => onFormUpdate('message', e.target.value)}
                    ></textarea>
                    {status?.message && (
                      <Col>
                        <p className={status.resStatus}>{status.message}</p>
                      </Col>
                    )}
                    <button
                      type="submit"
                      disabled={status?.resStatus === FormStatus.loading ? true : false}
                    >
                      <span>{buttonText}</span>
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
