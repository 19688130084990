import { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import navIcon1 from '../assets/icon/nav-icon1.svg';
import navIcon2 from '../assets/icon/nav-icon2.svg';
import navIcon3 from '../assets/icon/nav-icon3.svg';

import '../style/components/Footer.css';

const Footer: FC = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}></Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/zhenyuan-li/" target="_blank">
                <img src={navIcon1} alt="Icon" />
              </a>
              <a href="https://twitter.com/Zhenyuan0Li" target="_blank">
                <img src={navIcon2} alt="Icon" />
              </a>
              <a href="https://github.com/Zhenyuan-Li" target="_blank">
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
